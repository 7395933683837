<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="topBar"
    height="72"
    :elevation="2"
  >
    <v-btn
      class="mr-3"
      icon
      large
      @click="setDrawer(!drawer)"
    >
      <v-icon color="info">
        mdi-menu
      </v-icon>
    </v-btn>

    <v-spacer />


    <template v-if="validateButton">
      <v-btn
        class="px-5"
        color="primary"
        rounded
        dark
        :small="$vuetify.breakpoint.smAndUp"
        :x-small="$vuetify.breakpoint.smAndDown"
        :to="newRoute.ruta"
      >{{newRoute.name}}</v-btn>

    </template>


    <v-divider
      class="mx-4"
      vertical
    ></v-divider>

    <v-avatar size="50" left v-if="$vuetify.breakpoint.smAndUp">
      <v-icon size="50" color="primary" v-text="initial" v-if="$store.getters['auth/isAdmin']"></v-icon>
      <img v-else
        :src="logo"
      >
    </v-avatar>
    <v-menu transition="slide-y-transition" bottom class="mr-2" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn class="mr-3" color="transparent" small text tile v-on="on">
          <span class="font-weight-bold black--text" v-text="username" v-if="$vuetify.breakpoint.smAndUp"/>
          <v-avatar size="50" left v-if="$vuetify.breakpoint.xs">
            <v-icon size="50" color="primary" v-text="initial" v-if="$store.getters['auth/isAdmin']"></v-icon>
            <img v-else
              :src="logo"
            >
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item color="primary">
           <v-list-item-avatar>
              <v-avatar color="indigo" size="40">
                <img :src="logo" v-if="!$store.getters['auth/isAdmin']">
                <v-icon size="50" color="primary" v-text="initial" v-else></v-icon>
              </v-avatar>
           </v-list-item-avatar>
           <v-list-item-content>
              <v-list-item-title class="text-uppercase" v-text="username"></v-list-item-title>
           </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <!--  <v-list-item color="blue-grey lighten-1">
           <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
           </v-list-item-icon>
           <v-list-item-content>
              <v-list-item-title>Mi Perfil</v-list-item-title>
           </v-list-item-content>
        </v-list-item> -->
         <v-list-item color="blue-grey lighten-1" @click="logout">
           <v-list-item-icon>
               <v-icon>mdi-logout-variant</v-icon>
           </v-list-item-icon>
           <v-list-item-content>
              <v-list-item-title>Cerrar Sesion</v-list-item-title>
           </v-list-item-content>
        </v-list-item>
     </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifications: [
        'Mike John Responded to your email',
        'You have 5 new tasks',
        'You\'re now friends with Andrew',
        'Another Notification',
        'Another one',
      ],
    }),

    computed: {
      ...mapState(['drawer']),
      initial(){
        const letter = this.$store.getters['auth/user'] ? this.$store.getters['auth/user'].username.substr(0,1).toLowerCase() : 'a';
        return `mdi-alpha-${letter}-circle`;
      },
      username(){
        return this.$store.getters['auth/user'] ? this.$store.getters['auth/user'].username : '';
      },
      logo(){
        return this.$store.getters['auth/user'] ? `${process.env.VUE_APP_IMG_URL_API}images/restaurants/${this.$store.getters['auth/user'].logo}` : '';
      },
      newRoute() {
        if (this.$route.path === '/tc-admin/cartas'){
          return {ruta:'/tc-admin/carta/crear',name:'Nuevo menu'};
        }
        if (this.$route.path === '/tc-admin/platos'){
          return {ruta:'/tc-admin/platos/crear',name:'Nuevo plato'};
        }
        if (this.$route.path === '/tc-admin/promociones'){
          return {ruta:'/tc-admin/promociones/crear',name:'Nueva promoción'};
        }
        return '';
      },
      validateButton(){
        if (!this.$store.getters['auth/isAdmin']) {
          if (this.$route.path === '/tc-admin/cartas' || this.$route.path === '/tc-admin/promociones' || this.$route.path === '/tc-admin/platos' )  {
            return true;
          }else{
            return false;
          }
        }else{
          return false;
        }
      }

    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      logout(){
        this.$store.commit('SET_OVERLAY', true);
        this.$store.dispatch('auth/logout').then(()=>{
          this.$router.push({path: '/auth/login'});
          // this.$store.commit('SET_LOAD', false);
        })
        .catch(() =>{
          this.$store.commit('SET_OVERLAY', false);
        });
      },
      test(){
        //const rute = this.$router.path;
        //console.log('hola');
      }
    },
  }
</script>
<style>
  .app-menu-secundary{
      border-top: 1px solid #e8ecee !important;
   }
</style>
